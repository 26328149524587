import { PropsWithChildren, createContext, useCallback, useState } from "react"
import { GoalType } from "../models/Client"

type RMJourneyContextType = {
  selectedPortfolios: Record<string, string | null | undefined>
  updateSelectedPortfolio: (goalPortfolio: GoalPortfolio) => void
}

type GoalPortfolio = {
  goalType?: GoalType
  goalId?: string
  portfolio?: string | null
}

const defaults: RMJourneyContextType = {
  selectedPortfolios: {},
  updateSelectedPortfolio: () => {}
}

export const RMJourneyContext = createContext<RMJourneyContextType>(defaults)

const RMJourneyContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedPortfolios, setSelectedPortfolios] = useState<Record<string, string | null | undefined>>({})
  
  const updateSelectedPortfolio = useCallback((goalPortfolio: GoalPortfolio) => {
    setSelectedPortfolios((prev) => ({
      ...prev,
      [`${goalPortfolio?.goalType}_${goalPortfolio?.goalId}`]: goalPortfolio.portfolio
    }))
  }, [])

  return <RMJourneyContext.Provider value={{ selectedPortfolios, updateSelectedPortfolio }}>{children}</RMJourneyContext.Provider>
}

export default RMJourneyContextProvider
