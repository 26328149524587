

const mergeable = <T>(next:(t:T) => void) => {
  let open = false
  const queue: T[] = []
  const drainQueue = () => {
    queue.forEach(next)
    queue.splice(0, queue.length)
  }
  return {
    handler: (payload: T) => {
      // console.log("mergeable taking in", payload)
      if(open) {
        drainQueue()
        next(payload)
      } else {
        queue.push(payload)
        // console.log("mergable is not YET open, queued up", queue.length)
      }
    },
    enable: (is:boolean = true, merge?:(t:T) => T) => {
      open = is
      if (open) {
        if (merge) {
          // console.log(`those ${queue.length} queued up things will be merged now 🤞`)
          const nqueue = queue.map(merge)
          queue.splice(0, queue.length)
          queue.push(...nqueue)
        }
        // console.log("I can clearly see that we're 🔓")
        drainQueue()
      }
    }
  }
}

export default mergeable