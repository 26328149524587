import axios, { AxiosError, AxiosInstance } from "axios"
import axiosInstance from "../axiosInstance"

export type CPAnalyticsEventData = {
  event: string,
  element?: string
  source?: string,
  key?: number,
  width?: number,
  height?: number,
  value?: number,
  [x: string]: any
}

export type GAEvent = {
  event?: string
  category: string
  action: string
  label?: string
}

export type GTMEvent = {
  event: string
  label?: string
  action?: string
  category?: string
  virtualPath?: string
}

export type ViewEventData = CPAnalyticsEventData | GAEvent | GTMEvent

interface SendEventData {
  // uid: string,
  version?: string,
  gid: string,
  sid: string,
  page: string,
  screen?: string,
  game?: number,
  round?: number,
  width?: number,
  height?: number,
  value?: number,
  event: string,
}

export type EventData = SendEventData | CPAnalyticsEventData

let beaconSupported = !/Firefox/i.test(navigator.userAgent) && Boolean(navigator.sendBeacon) // disabled as some browsers like Brave ignore beacon calls

export const setBeaconSupported = (is:boolean) => beaconSupported = is


export const sendEvent = (data: { authToken: string } & (EventData | EventData[])) => {
  const { authToken } = data
  const eventUrl = `${import.meta.env.VITE_APP_API_BASE || ""}/api/events/ui`
  const eventPayload = { ...data, authToken: undefined }
  const eventConfig = { headers: { "X-Auth-Token": authToken } }
  
  const postEvent = (instance: AxiosInstance) =>
    instance.post(eventUrl, eventPayload, eventConfig)
    
  return beaconSupported
    ? navigator.sendBeacon(eventUrl, JSON.stringify(data))
      ? Promise.resolve(true)
      : postEvent(axiosInstance)
    : axios.post(eventUrl, eventPayload, eventConfig)
        .catch((err: AxiosError) =>
          err.response?.status === 401
            ? postEvent(axiosInstance)
            : Promise.reject(err)
        )
}


export interface BrowserEventData {
  uid: string,  // user (wealthclient) id
  gid?: string,  // game ID (experimentGroupId)
  sid?: string,  // session ID (browser tab session ID)
  location: string,
  hostname: string,
  app: string,
  app_version: string,
  os: string,
  os_version: string,
  browser: string,
  browser_version: string,
  width?: number,
  height?: number,
  tags?: { [x: string]: any } // these should come from config (or dynamic?)
}


export const sendBrowserEvent = (data: BrowserEventData, { authToken }: { authToken: string }) => {
  const url = `${import.meta.env.VITE_APP_API_BASE || ""}/api/events/browser`
  const config = { headers: { "X-Auth-Token": authToken } }
  const postEvent = (instance: AxiosInstance) => instance.post(url, data, config)
  return beaconSupported
    ? navigator.sendBeacon(url, JSON.stringify({ ...data, authToken }))
        ? Promise.resolve(true)
        : postEvent(axiosInstance)
    : axios.post(url, data, config)
        .catch((err: AxiosError) =>
          err.response?.status === 401
            ? postEvent(axiosInstance)
            : Promise.reject(err)
        )
}
