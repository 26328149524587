import axios from "axios"
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}



export type SessionInfo = {
  idToken: string,
  refreshToken: string,
  accessToken: string,
  idTokenPayload?: {
    email: string,
    isSuperAdmin: boolean
  }
}


export interface IAuth {
  sessionInfo?: SessionInfo
  authStatus?: AuthStatus
  signOut?: any
  isSuperAdmin: boolean
  signedIn: React.Dispatch<SessionInfo>
}

const defaultState: IAuth = {
  authStatus: AuthStatus.Loading,
  isSuperAdmin: false,
  signedIn: () => {},
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn: React.FunctionComponent<PropsWithChildren<any>> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn: React.FunctionComponent<PropsWithChildren<any>> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)
  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}


export function decodeJwt(token: string) {
  try {
    // Split the token into header, payload, and signature parts
    const [, payload] = token.split('.');

    // Base64Url decode the payload
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((char) => {
      return `%${('00' + char.charCodeAt(0).toString(16)).slice(-2)}`;
    }).join(''));

    // Parse the decoded payload as JSON
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return null;
  }
}


const AuthProvider: React.FunctionComponent<PropsWithChildren<any>> = ({ children }) => {
  const queryClient = useQueryClient()
  const [queryParams] = useSearchParams()
  const ssoToken = queryParams.get("_ssoToken")
  const ssoAccessToken = queryParams.get("_ssoAccessToken")
  const ssoRefreshToken = queryParams.get("_ssoRefreshToken")
  const [authStatus, setAuthStatus] = useState(() => {
    return (localStorage.getItem("idToken") && localStorage.getItem("refreshToken") && localStorage.getItem("accessToken") || (ssoToken && ssoAccessToken && ssoRefreshToken)) ? AuthStatus.SignedIn : AuthStatus.SignedOut
  })
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | undefined>(() => (localStorage.getItem("idToken") && localStorage.getItem("refreshToken") && localStorage.getItem("accessToken") || (ssoToken && ssoAccessToken && ssoRefreshToken)) ? {
    idToken: ssoToken ?? localStorage.getItem("idToken")!,
    refreshToken: ssoRefreshToken ?? localStorage.getItem("refreshToken")!,
    accessToken: ssoAccessToken ?? localStorage.getItem("accessToken")!,
    idTokenPayload: decodeJwt(ssoToken ?? localStorage.getItem("idToken")!)
  } : undefined)
  const isSuperAdmin = useMemo(() => sessionInfo?.idTokenPayload?.isSuperAdmin || false, [sessionInfo])
  const navigate = useNavigate()


  const signOut = useCallback(() => {
    if(sessionInfo?.refreshToken) {
      axios.post(`${import.meta.env.VITE_APP_API_BASE}/api/auth/logout`, { refreshToken: sessionInfo?.refreshToken }).
        catch(() => {})
    }
    queryClient.clear()
    setSessionInfo(undefined)
    sessionStorage.removeItem("firmId")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("idToken")
    setAuthStatus(AuthStatus.SignedOut)
    navigate("/")
  }, [navigate, queryClient, sessionInfo?.refreshToken])

  useEffect(() => {
    const handleRefreshToken = () => {
      if (sessionInfo) {
        setSessionInfo({ ...sessionInfo, accessToken: localStorage.getItem("accessToken")! })
      }
    };
    const handleLogout = () => signOut();

    window.addEventListener('refreshToken', handleRefreshToken);
    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('refreshToken', handleRefreshToken);
      window.removeEventListener('logout', handleLogout);
    };
  }, [sessionInfo, signOut]);

  useEffect(() => {
    if (sessionInfo) {
      localStorage.setItem("accessToken", sessionInfo.accessToken)
      localStorage.setItem("refreshToken", sessionInfo.refreshToken)
      localStorage.setItem("idToken", sessionInfo.idToken)
    }
  }, [sessionInfo])
  

  const signedIn = useCallback(
    (data: SessionInfo) => {
      setSessionInfo({
        ...data,
        idTokenPayload: decodeJwt(data.idToken)
      })
      setAuthStatus(AuthStatus.SignedIn)
    },
    [],
  )
  
  const state: IAuth = {
    authStatus,
    sessionInfo,
    signedIn,
    signOut,
    // changePassword,
    isSuperAdmin
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider