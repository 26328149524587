import { ErrorBoundary } from "react-error-boundary"
import { QueryClient, QueryClientProvider } from "react-query"
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom"
import AuthProvider from "./views/auth/AuthContext"

import { HelmetProvider } from "react-helmet-async"
import ErrorPage from "./components/Error/ErrorPage"
import ThemeContextProvider from "./contexts/ThemeContext"
import errorImage from "./layout/images/plug-unplugged.svg"
import ProductRoutes from "./routes/ProductRoutes"
import { ProductProvider } from "./contexts/ProductContext"
import Tracker from "./contexts/TrackingContext"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
})

function DisplayErrorDev(props: any) {
  return (
    <div className="w-full h-full bg-surface-100 flex flex-col items-center justify-center gap-11" role="alert">
      <img src={errorImage} alt="A plug that's been unplugged" />
      <div className="flex flex-col items-center justify-center gap-8 text-center">
        <h1 className="text-display font-semibold">Hey, what is going on?</h1>
        <p>{props.error?.toString()}</p>
        <button className="btn btn-primary btn-large" onClick={() => props.resetErrorBoundary()}>
          I have fixed it, let's try to re-render...
        </button>
      </div>
    </div>
  )
}



export default function App() {
  return <RouterProvider router={createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="*"
        element={
          <HelmetProvider>
            <ErrorBoundary FallbackComponent={process.env.NODE_ENV === "development" ? DisplayErrorDev : ErrorPage}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <ProductProvider>
                    <Tracker>
                      <ThemeContextProvider fallbackToBuiltInTheme={true}>
                        <ProductRoutes />
                      </ThemeContextProvider>
                    </Tracker>
                  </ProductProvider>
                </AuthProvider>
              </QueryClientProvider>
            </ErrorBoundary>
          </HelmetProvider>
        }
      />
    )
  )} />
}
