import { NavLink } from "react-router-dom"
import MainMenu from "../MainMenu"
import { FirmContext } from "../../contexts/FirmContext"
import { useCallback, useContext, useMemo, useState } from "react"
import clsx from "clsx"
import NavItemDropDown from "../NavItemDropdown"
import { AppContext } from "../../contexts/AppContext"
import { AuthContext } from "../../views/auth/AuthContext"
import { useTheme } from "../../contexts/ThemeContext"
import { AnimatePresence } from "framer-motion"
import Modal from "../../components/Modal/Modal"
import ImportClients from "../../pages/advisor/components/ImportModal/ImportClients"

const PortalNavBar = () => {
  const { firm } = useContext(FirmContext)
  const { userProfile } = useContext(AppContext)
  const { isSuperAdmin } = useContext(AuthContext)
  const theme = useTheme()
  const [modal, setModal] = useState<"importClients" | null>()

  const isFirmAdmin = useMemo(
    () => userProfile?.permissions?.find(p => p.firmId === firm?._id && Boolean(Array(p.permission).flatMap(_ => _).find(perm => perm === "ADMIN"))),
    [firm?._id, userProfile]
  )

  const activeLink = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      clsx(
        "transition ease-in-out duration-300 px-4 py-4 hover:bg-interactive-100 text-interactive-500 block w-min lg:w-auto",
        isActive ? "border-b-2 border-interactive-500 font-bold" : "font-semibold"
      ),
    []
  )


  return (
    <nav className="w-full px-8 bg-white font-bold text-sec flex items-center shadow-100">
      <ul className="flex items-center">
        {theme.navbar.links.clients && (
          <li>
            <NavLink to="/" className={activeLink}>
              Clients
            </NavLink>
          </li>
        )}
        {theme.navbar.links.import && (
          <li>
            <button
              onClick={() => setModal("importClients")}
              className="transition ease-in-out duration-300 px-4 hover:bg-interactive-100 text-interactive-500 py-4 font-semibold"
            >
              Import clients
            </button>
          </li>
        )}
        {import.meta.env.VITE_APP_FEATURE_ANALYTICS !== "false" && theme.navbar.links.analytics && (
          <li>
            <NavLink to="/preference-insights" className={activeLink}>
              Preference insights
            </NavLink>
          </li>
        )}
        {theme.navbar.links.archive && (
          <li>
            <NavLink to="/archived" className={activeLink}>
              Archived clients
            </NavLink>
          </li>
        )}
        {isFirmAdmin && theme.navbar.links.team && (
          <li>
            <NavLink to="/firm/team" className={activeLink}>
              Team
            </NavLink>
          </li>
        )}
        {isSuperAdmin && (
          <>
            {theme.gameType === "risk" && (
              <>
                <li>
                  <NavLink to="/firm/asset-classes" className={activeLink}>
                    Asset classes
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/firm/model-portfolios" className={activeLink}>
                    Model portfolios
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink to="/firm/manage" className={activeLink}>
                Firm Settings
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin" className={activeLink}>
                Site Admin
              </NavLink>
            </li>
          </>
        )}
        {theme.navbar.moreDropdown.show && (
          <li>
            <NavItemDropDown alignVertical="bottom" onImportClick={() => setModal("importClients")} />
          </li>
        )}
      </ul>
      <div className="ml-auto flex gap-2">
        {firm?.logo ? (
          <img alt={`${firm?.name} logo`} className="my-auto max-w-48 max-h-8" src={firm?.logo} />
        ) : firm?.name ? (
          <p className="my-auto">{firm?.name}</p>
        ) : (
          ""
        )}
        {theme.navbar.hamburgerMenu.show && <MainMenu />}
      </div>
      <AnimatePresence>
        {modal === "importClients" && (
          <Modal handleClose={() => setModal(null)}>
            <ImportClients handleClose={() => setModal(null)} />
          </Modal>
        )}
      </AnimatePresence>
    </nav>
  )
}

export default PortalNavBar
