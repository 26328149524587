import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';
import { BrowserEventData, CPAnalyticsEventData, EventData, GAEvent, ViewEventData, sendBrowserEvent, sendEvent } from '../api/analytics/analytics';
import mergeable from '../api/analytics/mergeable';
import { AuthContext, decodeJwt } from '../views/auth/AuthContext';
import { useProduct } from './ProductContext';


const postMessageToParent = (data: { event: string; path: string; href?: string }) => {
  if (window.parent && window.parent !== window) {
    window.parent.postMessage(data, "*")
  }
}


interface TrackingContextType {
  trackViewEvent: (data:ViewEventData) => void
}

export const defaults:TrackingContextType = {
  trackViewEvent: () => {}
}

interface ViewportSize {
  width: number,
  height: number,
}

const viewportSize: ViewportSize = { width: window.innerWidth, height: window.innerHeight }



export const TrackingContext = createContext(defaults)

async function hashJwt(token: string): Promise<string> {
  const encoder = new TextEncoder()
  const data = encoder.encode(token)

  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  // Convert to base64url
  const base64 = btoa(String.fromCharCode(...new Uint8Array(hashBuffer)))
  const base64url = base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '') // remove padding

  return base64url
}

const sessionIdFromRefreshToken = (token?: string): string | undefined => {
  if (!token) return undefined

  try {
    const decoded = decodeJwt(token)
    if (typeof decoded === 'object' && decoded.id) {
      return String(decoded.id)
    }

    const signature = token.split('.')[2]
    if (signature) {
      return signature
    }

    const issuedAt = decoded.iat ? new Date(decoded.iat * 1000).toISOString() : undefined
    return issuedAt || token
  } catch {
    return token
  }
}

const uidFromIdToken = (token?: string) => {
  if (token) {
    const decoded = decodeJwt(token)
    return decoded.sub
  }
}


const Tracker = ({ children }: React.PropsWithChildren) => {

  const { sessionInfo } = useContext(AuthContext)
  const uid = uidFromIdToken(sessionInfo?.idToken)
  const gid = sessionIdFromRefreshToken(sessionInfo?.refreshToken)
  const authToken = sessionInfo?.accessToken
  const eventsAPI = true

  const { product } = useProduct()
  
  const gaMeasurementId = import.meta.env.VITE_APP_GA_TRACKING_ID
  const gtm = {
    ids: ["GTM-MTFPC2SQ"],
    url: "https://cpta.capitalpreferences.com"
  }

  const sid = useMemo(() => {
    const existingSid = sessionStorage.getItem("sid")
    if (existingSid) {
      return existingSid
    } else {
      const generatedSid = crypto.randomUUID()
      sessionStorage.setItem("sid", generatedSid)
      return generatedSid
    }
  }, [])

  const location = useLocation()

  const { handler: sendTrackingEvent, enable: flushAndEnableTracking } = useMemo(() => mergeable(sendEvent), [])

  useEffect(() => {
    if (gaMeasurementId) {
      ReactGA.initialize(gaMeasurementId);
    }
  }, [gaMeasurementId])

  useEffect(() => {
    if(uid && gid && sid && eventsAPI && authToken) {
      // console.log("tracker context:", { uid, experimentGroupId, sid, eventsAPI, authToken })
      const { protocol, hostname, port, pathname, search } = window.location
      sendBrowserEvent({
        uid,
        gid,
        sid,
        location: `${protocol}//${hostname}${(port && port !== '80' && port !== '443') ? `:${port}` : ''}${pathname}${search}`,
        hostname,
        app: product || "advisor",
        app_version: import.meta.env.VITE_APP_VERSION || "",
        browser: browserName,
        browser_version: browserVersion,
        os: osName,
        os_version: osVersion,
        width: window.innerWidth,
        height: window.innerHeight
      } as BrowserEventData, { authToken }).then(() =>
        flushAndEnableTracking(true, prev => ({ ...prev, sid, gid: gid }))
      )
    }
  }, [uid, sid, gid, flushAndEnableTracking, eventsAPI, authToken, product])

  const trackEvent = useCallback(
    (data: EventData) => {
      if (eventsAPI && sid && gid && authToken && data) {
        sendTrackingEvent({
          ...data,
          page: location.pathname,
          sid,
          gid: gid,
          authToken,
          width: window.innerWidth !== viewportSize.width || window.innerHeight !== viewportSize.height ? window.innerWidth : undefined,
          height: window.innerWidth !== viewportSize.width || window.innerHeight !== viewportSize.height ? window.innerHeight : undefined,
        })
        viewportSize.width = window.innerWidth
        viewportSize.height = window.innerHeight
      }
    },
    [eventsAPI, sid, gid, authToken, sendTrackingEvent, location.pathname]
  )
  
 
  useEffect(() => {
    postMessageToParent({
      event: "enter",
      path: location.pathname
    })
    trackEvent({ event: "enter" })
    return () => {
      postMessageToParent({
        event: "leave",
        path: location.pathname
      })
      trackEvent({ event: "leave" })
    }
  }, [trackEvent, location.pathname])


  const trackViewEvent = useCallback((e: ViewEventData) => {
    if (e.action && gaMeasurementId) {
      ReactGA.event(e as GAEvent)
    }
    
    if (e.event) {
      trackEvent(e as CPAnalyticsEventData)
    }
  }, [trackEvent, gaMeasurementId])


  const iframeAwareViewTracker = useCallback((e: ViewEventData) => {
    if (e.event === "open" || e.event === "close") {
      postMessageToParent({
        ...(e as CPAnalyticsEventData),
        path: location.pathname
      })
    }
    trackViewEvent(e)
  }, [location.pathname, trackViewEvent])

  return (
    <TrackingContext.Provider value={{ trackViewEvent: iframeAwareViewTracker }}>
      {children}
    </TrackingContext.Provider>
  )
}

export default Tracker
