import { Route, Routes } from "react-router-dom"
import AppWithAuth from "../AppWithAuth"
import StandardLayout from "../layout/StandardLayout"
import PortalNavBar from "../layout/navbars/PortalNavBar"
import ThemeContextProvider from "../contexts/ThemeContext"
import AppContextProvider from "../contexts/AppContext"

const PrivateMarketsApp = () => (
  <AppWithAuth>
    <AppContextProvider>
      <ThemeContextProvider fallbackToBuiltInTheme={false}> {/* theme needs firm loaded */}
        <StandardLayout
          navbar={
            <Routes>
              <Route path="/*" element={<PortalNavBar />} />
            </Routes>
          }
        >
          <div className="flex-full-content-center">Private Markets. By Capital Preferences.</div>
        </StandardLayout>
      </ThemeContextProvider>
    </AppContextProvider>
  </AppWithAuth>
)

export default PrivateMarketsApp
