import { AxiosError } from "axios"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { UserProfileUpdateRequest } from "../../../api/userProfiles"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import InputCode from "../../../components/InputCode/InputCode"
import TextInput from "../../../components/TextInput/TextInput"
import { AppContext } from "../../../contexts/AppContext"
import { validateEmail } from "../../../lib/email"
import { Password } from "../../../views/auth/AuthComponents"
import { AuthContext } from "../../../views/auth/AuthContext"
import axiosInstance from "../../../api/axiosInstance"
import ErrorMessage from "../../../components/Error/ErrorMessage"

export type Errors = {
  firstName?: string
  lastName?: string
  email?: string
  confirmEmail?: string

  // for email change
  password?: string
  mobile?: string
}

const AccountSettings: React.FunctionComponent<{ handleClose: () => void }> = ({ handleClose }) => {
  const { userProfile, updateUserProfile } = useContext(AppContext)
  const [errors, setErrors] = useState<Errors>({})
  const [confirmEmail, setConfirmEmail] = useState<string>()
  const [data, setData] = useState<UserProfileUpdateRequest>({})
  const [screen, setScreen] = useState<"updatePersonalInfo" | "enterCode" | "emailVerificationSuccess">("updatePersonalInfo")
  const [timeToLogout, setTimeToLogout] = useState<number>()
  const { signOut } = useContext(AuthContext)
  const [isEmailChanged, setIsEmailChanged] = useState(false)

  useEffect(() => {
    if (timeToLogout !== undefined) {
      if (timeToLogout > 0) {
        const timer = setTimeout(() => {
          setTimeToLogout(timeToLogout! - 1)
        }, 1000)
        return () => {
          clearTimeout(timer)
        }
      } else {
        signOut()
      }
    }
  }, [timeToLogout, signOut])

  const isMobileChanged = (data.mobile !== undefined && data.mobile !== userProfile?.mobile) || (userProfile?.mobile && !data.mobile)
  const isFormChanged =
    (data.firstName !== undefined && data.firstName !== userProfile?.firstName) ||
    (data.lastName !== undefined && data.lastName !== userProfile?.lastName) ||
    isEmailChanged ||
    isMobileChanged

  useEffect(() => {
    const errs: Errors = {
      firstName: data.firstName !== undefined && data.firstName.trim() === "" ? "First name cannot be empty" : undefined,
      lastName: data.lastName !== undefined && data.lastName.trim() === "" ? "Last name cannot be empty" : undefined,
      email: data.email !== undefined && !validateEmail(data.email.trim()) ? "Please enter a valid email" : undefined,
      confirmEmail:
        isEmailChanged && (!validateEmail(confirmEmail ? confirmEmail.trim() : "") || data.email !== confirmEmail) ? "Emails do not match" : undefined,
      mobile:
        data.mobile !== undefined && !/^\s*\+\d{2,3}[ -.]\d+([ -.]\d+)*\s*$/.test(data.mobile)
          ? "Please enter a valid phone number with country code"
          : undefined,
      password: isEmailChanged && (data.password?.length ?? 0) < 8 ? "Enter password" : ""
    }
    setErrors(errs)
  }, [data, confirmEmail, isEmailChanged])

  const {
    isSuccess,
    isLoading: isUpdating,
    error: updateError,
    mutate: doUpdate
  } = useMutation<void, AxiosError, UserProfileUpdateRequest>(["updateProfile", data], updateUserProfile)

  const {
    isSuccess: otpIsSuccess,
    isLoading: otpIsLoading,
    error: otpError,
    mutate: confirmEmailOTP
  } = useMutation<any, AxiosError, string>(["confirmOtp", data], (otp) =>
    axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/user/confirm-email`, { otp }).then((res) => res.data)
  )

  useEffect(() => {
    if (updateError) {
      setErrors((prev) => ({ ...prev, update: updateError?.message ?? "Error:" + updateError }))
    }
    if (isSuccess) {
      if (!isEmailChanged) {
        handleClose()
      } else {
        setScreen("enterCode")
      }
    }
  }, [handleClose, isEmailChanged, isSuccess, updateError])

  useEffect(() => {
    if (otpIsSuccess) {
      setScreen("emailVerificationSuccess")
      setTimeToLogout(5)
    }
  }, [otpIsSuccess])

  const saveChanges = useCallback(() => {
    doUpdate(data)
  }, [data, doUpdate])

  const isButtonEnabled = isFormChanged && Object.values(errors).every((x) => x === null || x === undefined || x === "") && !isUpdating
  console.log({ isFormChanged, isButtonEnabled, errors: Object.values(errors) })

  const screens = {
    updatePersonalInfo: (
      <div className="flex flex-col gap-6">
        <h1 className="modal-title">Update personal information</h1>
        <div className="w-full m-auto flex flex-col gap-6">
          <TextInput
            label="First name"
            value={data.firstName ?? userProfile!.firstName}
            name="firstName"
            onChange={(val) => setData((prev) => ({ ...prev, firstName: val }))}
            error={errors.firstName}
          />
          <TextInput
            label="Last name"
            value={data.lastName ?? userProfile!.lastName}
            name="lastName"
            onChange={(val) => setData((prev) => ({ ...prev, lastName: val }))}
            error={errors.lastName}
          />
          <TextInput
            label="Email address"
            name="email"
            onChange={(val) => {
              setData((prev) => ({ ...prev, email: val.replace(/\s+/g, "").trim() }))
              if (val === userProfile?.email) {
                setConfirmEmail("")
                setData((d) => ({ ...d, password: "" }))
              } else {
                setIsEmailChanged(true)
              }
            }}
            value={data.email ?? userProfile!.email}
            error={errors.email}
          />
          <TextInput
            label="Confirm email address"
            name="confirmEmail"
            onChange={setConfirmEmail}
            value={confirmEmail}
            isDisabled={!isEmailChanged}
            error={errors.confirmEmail}
          />
          <AnimatePresence>
            {isEmailChanged && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Password
                  label="To change your email, please also enter your password"
                  id="password"
                  setPassword={(password) => setData((prev) => ({ ...prev, password }))}
                  password={data.password ?? ""}
                  passwordIsValid={Boolean(data.password?.length)}
                  error={errors.password}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="gap-4 w-full flex justify-center m-auto">
          <div role="alert">{updateError && <ErrorMessage id="account-settings" message={(updateError.response?.data as any) ?? updateError.message} />}</div>
          <button className="btn btn-secondary btn-medium text-sec w-44" onClick={handleClose}>
            Cancel
          </button>
          <button className={clsx("btn btn-primary btn-medium text-sec w-44", {})} disabled={!isButtonEnabled} onClick={saveChanges}>
            {isUpdating ? <Loading type="dots" /> : <>Save changes</>}
          </button>
        </div>
      </div>
    ),
    enterCode: (
      <div className="flex flex-col justify-center items-center mx-auto my-auto">
        <div className="">
          <h1 className="modal-title">Verify your email</h1>
          <p className="modal-description">A verification code has been sent to your email. Please enter the six-digit code below to change your email.</p>
          <div className="pb-[41px] pt-[25px] input-wrapper">
            <InputCode length={6} loading={otpIsLoading} onComplete={confirmEmailOTP} />
          </div>
          <div role="alert">
            {otpError && (
              <div className="text-center mb-4">
                <ErrorMessage id="account-settings-code" message="Invalid code entered" />
              </div>
            )}
          </div>
          <p className="text-main-500 text-center">
            {otpError ? "Please enter a valid code" : "If you didn't receive an email, please check your spam folder."}
          </p>
        </div>
      </div>
    ),
    emailVerificationSuccess: (
      <div>
        Your email has been changed successfully. You will be logged out in {timeToLogout} seconds. Please sign in again with the new email address.{" "}
        <button className="btn border-0 text-2xl underline" onClick={signOut}>
          Log out now
        </button>
      </div>
    )
  }

  return <div className="w-[500px]">{screens[screen]}</div>
}

export default AccountSettings
